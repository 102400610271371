<template>
  <div style="height: 100%">
  <!--【版头】标题 -->
  <nesi-user-common :title="`地点管理`">
    <template #button>
      <el-button type="primary" plain @click="setAddTableRowForm">新增地点申请</el-button>
    </template>
    <!--【版头】查询条件 -->
    <template #query>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="审核状态">
          <el-select v-model="form.status" clearable placeholder="请选择审核状态">
            <el-option label="待审核" value="0"/>
            <el-option label="审核不通过" value="1"/>
            <el-option label="审核通过" value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" style="width: 293px;">
          <el-input v-model="form.name" clearable placeholder="请输入名称关键字"/>
        </el-form-item>
        <el-form-item label="地址" style="width: 293px;">
          <el-input v-model="form.address" clearable placeholder="请输入地址关键字"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </template>
    <!--【主区】列表 -->
    <template #content>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{
          textAlign: 'center',
          backgroundColor: '#f5f7fa'
        }">
        <el-table-column prop="name" label="小区名称" width="100" />
        <el-table-column prop="address" label="地址" />
        <el-table-column prop="longitude" label="经度" width="100" />
        <el-table-column prop="latitude" label="纬度" width="100" />
        <el-table-column prop="createTime" label="申请时间" width="160" />
        <el-table-column prop="statusName" label="审核状态" width="100" />
        <el-table-column prop="reason" label="不通过原因" width="100" />
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button v-show="scope.row.status === 0 "
              type="primary" plain size="small" class="list-btn"
              @click="setEditTableRowForm(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        @current-change="currentChange"
        :total="total"
        :page-size="form.pageSize"
        style="text-align: right; margin: 10px; 0"
      />
    </template>
  </nesi-user-common>
  <!--【弹窗】新增 -->
  <el-dialog title="新增地点申请" width="900px"
    v-model="addTableRowDialogVisible">
    <div id="map_add" style="width: 700px;height: 300px;margin:0px 0px 30px 80px;"></div>
    <el-form :inline="true" :model="addTableRowForm" class="demo-form-inline">
      <el-form-item label="名称" label-width="120px">
        <el-input v-model="addTableRowForm.name" clearable placeholder="请输入小区名称" type="text" maxlength=128 style="width:300px;"/>
      </el-form-item>
      <el-form-item label="地址" label-width="50px">
        <el-input v-model="addTableRowForm.address" clearable placeholder="请输入小区地址" type="textarea" :rows="2" maxlength=255 style="width:300px;"/>
      </el-form-item>
      <el-form-item label="经度" label-width="120px">
        <el-input v-model="addTableRowForm.longitude" clearable placeholder="请输入小区经度" type="text" maxlength=64 style="width:300px;"/>
      </el-form-item>
      <el-form-item label="纬度" label-width="50px">
        <el-input v-model="addTableRowForm.latitude" clearable placeholder="请输入小区纬度" type="text" maxlength=64 style="width:300px;"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addTableRowDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addTableRow">提交</el-button>
      </span>
    </template>
  </el-dialog>
  <!--【弹窗】修改 -->
  <el-dialog title="修改地点申请" width="900px"
    v-model="editTableRowDialogVisible">
    <div id="map_edit" style="width: 700px;height: 300px;margin:0px 0px 30px 80px;"></div>
    <el-form :inline="true" :model="editTableRowForm" class="demo-form-inline">
      <el-form-item label="名称" label-width="120px">
        <el-input v-model="editTableRowForm.name" clearable placeholder="请输入小区名称" type="text" maxlength=128 style="width:300px;"/>
      </el-form-item>
      <el-form-item label="地址" label-width="50px">
        <el-input v-model="editTableRowForm.address" clearable placeholder="请输入小区地址" type="textarea" :rows="2" maxlength=255 style="width:300px;"/>
      </el-form-item>
      <el-form-item label="经度" label-width="120px">
        <el-input v-model="editTableRowForm.longitude" clearable placeholder="请输入小区经度" type="text" maxlength=64 style="width:300px;"/>
      </el-form-item>
      <el-form-item label="纬度" label-width="50px">
        <el-input v-model="editTableRowForm.latitude" clearable placeholder="请输入小区纬度" type="text" maxlength=64 style="width:300px;"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editTableRowDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editTableRow">提交</el-button>
      </span>
    </template>
  </el-dialog>

  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { defineComponent, reactive, ref, onMounted } from 'vue'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import { findCommunityApply, addCommunityApply, editCommunityApply } from '@/api/user-community'
defineComponent({
  NesiUserCommon
})

/**
 * 查询
 */
const total = ref(0)
const form = reactive({
  pageNo: 1,
  pageSize: 10,
  status: '',
  name: '',
  address: ''
})
const tableData = ref([])
const findTableList = async () => {
  const resp = await findCommunityApply(form)
  total.value = resp.total
  tableData.value = resp.records.map(item => {
    let statusName = '待审核'
    if (item.status === 1) {
      statusName = '审核通过'
    } else if (item.status === 2) {
      statusName = '审核不通过'
    }
    return {
      id: item.id,
      name: item.name,
      address: item.address,
      longitude: item.longitude,
      latitude: item.latitude,
      createTime: item.createTime,
      status: item.status,
      statusName: statusName,
      reason: item.reason
    }
  })
  console.log(resp)
}

/**
 * 添加
 */
const addTableRowDialogVisible = ref(false)
const addTableRowForm = reactive({
  name: '',
  address: '',
  longitude: '',
  latitude: ''
})
const resetAddTableRowForm = () => {
  addTableRowForm.name = ''
  addTableRowForm.address = ''
  addTableRowForm.longitude = ''
  addTableRowForm.latitude = ''
}
const setAddTableRowForm = () => {
  addTableRowDialogVisible.value = true
  // 加载地图
  window.setTimeout(function() {
    initMap('map_add')
  }, 100)
}
const addTableRow = async (row) => {
  const addResp = await addCommunityApply(addTableRowForm)
  console.log(addResp)
  addTableRowDialogVisible.value = false
  ElMessage({ type: 'success', message: '添加成功' })
  resetAddTableRowForm()
  findTableList()
}

/**
 * 修改
 */
const editTableRowDialogVisible = ref(false)
const editTableRowForm = reactive({
  id: '',
  name: '',
  address: '',
  longitude: '',
  latitude: ''
})
const resetEditTableRowForm = () => {
  editTableRowForm.id = ''
  editTableRowForm.name = ''
  editTableRowForm.address = ''
  editTableRowForm.longitude = ''
  editTableRowForm.latitude = ''
}
const setEditTableRowForm = (row) => {
  editTableRowForm.id = row.id
  editTableRowForm.name = row.name
  editTableRowForm.address = row.address
  editTableRowForm.longitude = row.longitude
  editTableRowForm.latitude = row.latitude
  editTableRowDialogVisible.value = true
  // 加载地图
  window.setTimeout(function() {
    initMap('map_edit', row.latitude, row.longitude)
  }, 100)
}
const editTableRow = async (row) => {
  const editResp = await editCommunityApply(editTableRowForm)
  console.log(editResp)
  editTableRowDialogVisible.value = false
  ElMessage({ type: 'success', message: '修改成功' })
  resetEditTableRowForm()
  findTableList()
}

/**
 * 分页
 */
const currentChange = value => {
  form.pageNo = value
  findTableList()
}
onMounted(() => {
  findTableList()
})
const onSubmit = () => {
  form.pageNo = 1
  findTableList()
}

/**
 * 加载地图（百度）
 */
let map = {}
let marker = {}
const BMap = window.BMap
const initMap = async (divId, latitude, longitude) => {
  const lat = latitude || 22.821002
  const lng = longitude || 108.372744
  // 创建点坐标
  const point = new BMap.Point(lng, lat)
  // 初始化地图
  map = new BMap.Map(divId)
  // 初始化地图（设置中心点坐标和地图级别）
  map.centerAndZoom(point, 13)
  // 移动到中心点坐标
  map.panTo(point)
  // 创建标注
  marker = new BMap.Marker(point)

  marker.addEventListener('dragend', setPointInfo)

  // 设置标注可拖拽
  marker.enableDragging()
  // 监听标注拖拽（更新位置信息）
  // marker.addEventListener('dragend', setPointInfo)
  // 添加标注到地图
  map.addOverlay(marker)
  // 启用滚轮放大缩小
  map.enableScrollWheelZoom()
  // 启用放大缩小尺
  map.addControl(new BMap.NavigationControl())
  // 默认标注拖拽（初始化位置信息）
  await setPointInfo(point)
}
// 获取标注点定位信息（地址,经度,纬度）
const getPointInfo = async (e) => {
  let thisPoint = e
  if (e.point !== undefined) {
    thisPoint = e.point
  }
  var gc = new BMap.Geocoder()
  return new Promise((resolve, reject) => {
    gc.getLocation(thisPoint, function (rs) {
      // 获取标注点信息
      var ac = rs.addressComponents
      const address = ac.province + ac.city + ac.district + ac.street + ac.streetNumber
      const latitude = rs.point.lat
      const longitude = rs.point.lng
      // 显示标注点地址
      const label = new BMap.Label(address, { offset: new BMap.Size(20, -10) })
      map.removeOverlay(marker.getLabel())
      marker.setLabel(label)
      // 返回标注点信息
      resolve({
        address: address,
        latitude: latitude,
        longitude: longitude
      })
    })
  })
}
// 设置标注点定位信息（地址,经度,纬度）
const setPointInfo = (e) => {
  getPointInfo(e).then(thisPointInfo => {
    // 设置表单内容
    if (editTableRowForm.id !== '') {
      // 修改表单（id不为空）
      editTableRowForm.address = thisPointInfo.address
      editTableRowForm.latitude = parseFloat(thisPointInfo.latitude).toFixed(6) + ''
      editTableRowForm.longitude = parseFloat(thisPointInfo.longitude).toFixed(6) + ''
    } else {
      // 添加表单
      addTableRowForm.address = thisPointInfo.address
      addTableRowForm.latitude = parseFloat(thisPointInfo.latitude).toFixed(6) + ''
      addTableRowForm.longitude = parseFloat(thisPointInfo.longitude).toFixed(6) + ''
    }
  })
}

</script>
<style lang="sass"></style>
